const config = {
    selectors: {
        surveyDialog: '.survey-dialog',
    },
    classNames: {},
};

function init() {
    $(config.selectors.surveyDialog).each((i, el) => {
        const $surveyDialog = $(el);
        initSurveyDialog($surveyDialog);
    });
}

function initSurveyDialog($surveyDialog) {
    $surveyDialog.survey();
}

init();
