/* eslint-disable func-names */
/* eslint-disable max-len */
// eslint-disable-next-line func-names
(function ($) {
    $.fn.survey = function () {
        // setup config data
        const config = {
            selectors: {
                acceptSurvey: '.survey-dialog__btn--accept',
                rejectSurvey: '.survey-dialog__btn--reject',
            },
            classNames: {},
            cookie: {
                pageCountCookie: 'mtk_page_count',
                surveyCookieParticipation: 'mtk_survey_participation',
                surveyCookie: 'mtk_survey_value',
                minDuration: 7,
                maxDuration: 30,
            },
        };
        // init dialog and buttons
        const $surveyDialog = this;
        const $rejectSurveyButtons = $surveyDialog.find(config.selectors.rejectSurvey);
        const $acceptSurveyButtons = $surveyDialog.find(config.selectors.acceptSurvey);

        // set cookie duration default values
        const $cookieDurationMin = Number($surveyDialog.attr('data-min')) * 1000 * 60 * 60 * 24 || config.cookie.minDuration * 1000 * 60 * 60 * 24;
        const $cookieDurationMax = Number($surveyDialog.attr('data-max')) * 1000 * 60 * 60 * 24 || config.cookie.maxDuration * 1000 * 60 * 60 * 24;
        let $cookieDuration = $cookieDurationMin;

        // init function
        function init() {
            // get cookie
            const cookies = document.cookie.split(';');
            let surveyCookieParticipationExist = false;
            let surveyCookieExist = false;
            let pageCountCookieExist = false;
            const path = window.location.pathname;
            const page = path.split('/').pop();

            cookies.forEach((cookie) => {
                while (cookie.charAt(0) === ' ') {
                    // eslint-disable-next-line no-param-reassign
                    cookie = cookie.substring(1);
                }
                // check if page count cookie exist
                if (cookie.indexOf(config.cookie.pageCountCookie) === 0) {
                    pageCountCookieExist = cookie.substring(config.cookie.pageCountCookie.length + 1, cookie.length);
                }
                // check if main cookie exist
                if (cookie.indexOf(config.cookie.surveyCookieParticipation) === 0) {
                    surveyCookieParticipationExist = cookie.substring(config.cookie.surveyCookieParticipation.length + 1, cookie.length);
                }
                // check if value cookie exist
                if (cookie.indexOf(config.cookie.surveyCookie) === 0) {
                    surveyCookieExist = cookie.substring(config.cookie.surveyCookie.length + 1, cookie.length);
                }
            });
            // init survey dialog
            if (surveyCookieParticipationExist) {
                if (!surveyCookieExist) {
                    // if main cookie exist and value cookie do not exist set expiration date to max and show dialog
                    $cookieDuration = $cookieDurationMax;
                    initSurvey();
                }
            } else {
                // if main cookie do not exist check if page count cookie exist
                // eslint-disable-next-line no-lonely-if
                if (pageCountCookieExist) {
                    const pages = pageCountCookieExist.split('#');
                    let pageExist = false;
                    if (pages.length === 3) {
                        // if 3 separate pages opened
                        initSurvey();
                    } else {
                        // if 3 separate pages NOT opened compare if page exist in cookie
                        pages.forEach((singlePage) => {
                            if (singlePage === page) {
                                pageExist = true;
                            }
                        });
                        if (!pageExist) {
                            if (pages.length === 2) {
                                // if page is 3rd separate page init survey
                                initSurvey();
                            }
                            // if page do not exist in cookie add page to cookie
                            document.cookie = `${config.cookie.pageCountCookie}=${pageCountCookieExist}#${page};expires=${new Date(2147483647 * 1000).toUTCString()};path=/`;
                        }
                    }
                } else {
                    // if main cookie do not exist and page count cookie do not exist create pagecount cookie
                    document.cookie = `${config.cookie.pageCountCookie}=${page};expires=${new Date(2147483647 * 1000).toUTCString()};path=/`;
                }
            }
        }

        // set cookie
        function setCookie(cookieValue) {
            const dmin = new Date();
            const dmax = new Date();
            dmin.setTime(dmin.getTime() + $cookieDuration);
            dmax.setTime(dmax.getTime() + $cookieDurationMax);
            // if duration is min than show main cookie
            if ($cookieDuration === $cookieDurationMin) {
                document.cookie = `${config.cookie.surveyCookieParticipation}=${cookieValue};expires=${new Date(2147483647 * 1000).toUTCString()};path=/`;
            }
            // if value is accepted set value cookie duration to 'unlimited'
            const expires = cookieValue !== 'accept' ? `expires=${dmin.toUTCString()}` : `expires=${dmax.toUTCString()}`;
            document.cookie = `${config.cookie.surveyCookie}=${cookieValue};${expires};path=/`;
        }

        // init dialog
        function initSurvey() {
            // init dialog reject button
            $rejectSurveyButtons.each((i, el) => {
                const $rejectSurveyButton = $(el);
                initRejectSurveyButton($rejectSurveyButton);
            });

            // init dialog accept button
            $acceptSurveyButtons.each((i, el) => {
                const $acceptSurveyButton = $(el);
                initAcceptSurveyButton($acceptSurveyButton);
            });

            // show dialog
            surveyDialogShow();
        }

        function initRejectSurveyButton($rejectSurveyButton) {
            $rejectSurveyButton.on('click', (e) => {
                // on reject set the cookie and close dialog
                e.preventDefault();
                setCookie('reject');
                surveyDialogHide();
            });
        }

        function initAcceptSurveyButton($acceptSurveyButton) {
            $acceptSurveyButton.on('click', (e) => {
                // on accept set the cookie
                e.preventDefault();
                setCookie('accept');
                window.open($acceptSurveyButton.attr('href'), '_blank');
                surveyDialogHide();
            });
        }

        // hide survey
        function surveyDialogHide() {
            $surveyDialog.hide();
        }

        // show survey
        function surveyDialogShow() {
            $surveyDialog.show();
        }

        init();
    };
}(jQuery));
